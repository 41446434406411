import { gql } from '@apollo/client';

const USER_TASK_FRAGMENT = gql`
  fragment UserTask on UserTask {
    id
    taskTemplate
    customTemplateContext
    status
    displayTitle
    dueDate
    explanatoryText
    userRemovable
    required
  }
`;

export default USER_TASK_FRAGMENT;
