import EqualHousingIcon from '$/assets/icons/equal_housing_icon.svg';
import LogoItem from '$/components/Atoms/LogoItem';
import StatusBars from '$/components/Molecules/StatusBars';

import useConnect from './connect';
import Footer from './Share/Footer';
import * as Styled from './styles';
import type { Props } from './types';

/**
 * This layout is used for authenticated sessions.
 */
export default function MainLayout({
  centerContent,
  children,
  className,
  showTopBar = true,
  topBarHideUser,
  topBarText,
  topBarWithoutBorder,
  topBarHandleBack,
  showFooter = false,
  fixedButtonPosition = false,
  statusProps,
}: Props): JSX.Element {
  const { isDesktop, isAuthenticated, hasTasks } = useConnect();

  return isAuthenticated ? (
    <Styled.Container className={className}>
      {showTopBar &&
        (isDesktop ? (
          <Styled.NavbarDesktop
            text={topBarText}
            handleBack={topBarHandleBack}
            noBottomShadow={Boolean(statusProps)}
            hasTasks={hasTasks}
          />
        ) : (
          <Styled.Navbar
            hideUser={topBarHideUser}
            text={topBarText}
            withoutBorder={topBarWithoutBorder}
            handleBack={topBarHandleBack}
            hasTasks={hasTasks}
          />
        ))}
      <Styled.Content $centerContent={centerContent}>
        {statusProps && <StatusBars {...statusProps} />}
        {children}
      </Styled.Content>
      {showFooter && (
        <Footer fixedButtonPosition={fixedButtonPosition}>
          <LogoItem>
            <>
              <EqualHousingIcon />
              <Styled.Text>Equal Housing Opportunity</Styled.Text>
            </>
          </LogoItem>
        </Footer>
      )}
    </Styled.Container>
  ) : (
    <div />
  );
}
