import type { UserTaskFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

export default function normalizeUserTasks(
  input: WithoutNulls<UserTaskFragment>[],
) {
  return input.map((task) => ({
    id: task.id,
    taskTemplate: task.taskTemplate,
    customTemplateContext: task.customTemplateContext,
    status: task.status,
    displayTitle: task.displayTitle,
    dueDate: task.dueDate,
    explanatoryText: task.explanatoryText,
    userRemovable: task.userRemovable,
    required: task.required,
  }));
}

export type UserTasks = ReturnType<typeof normalizeUserTasks>;
