export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  Datetime: string;
  JSON: Record<string, unknown>;
  Upload: File;
};

export type AcceptRenewalOfferInput = {
  addedRentableItems?: Maybe<Array<Maybe<RentableItemCategoryInput>>>;
  addedResidents?: Maybe<Scalars['Boolean']>;
  removedRentableItems?: Maybe<Array<Maybe<ChargeInput>>>;
  removedResidents?: Maybe<Array<ResidentInput>>;
  renewalOfferId?: Maybe<Scalars['ID']>;
  renewalOfferPricingTermId?: Maybe<Scalars['ID']>;
  residentInfo: ResidentInfoInput;
  totalMonthlyChargeRange?: Maybe<MinMaxFloatRangeInput>;
  updatedResidentInfo?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  secondary?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type Amenity = {
  __typename?: 'Amenity';
  description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type AuthenticationPayload = {
  __typename?: 'AuthenticationPayload';
  accessToken: Scalars['String'];
  currentUser: User;
  refreshToken: Scalars['String'];
};

export type Charge = {
  __typename?: 'Charge';
  amount: Scalars['Float'];
  frequency: ChargeFrequency;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ChargeType;
};

export enum ChargeFrequency {
  Annually = 'ANNUALLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  OneTime = 'ONE_TIME',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
}

export type ChargeInput = {
  amount: Scalars['Float'];
  frequency: ChargeFrequency;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ChargeType;
};

export enum ChargeType {
  Discount = 'DISCOUNT',
  Fee = 'FEE',
  RentableItem = 'RENTABLE_ITEM',
}

export type Community = {
  __typename?: 'Community';
  address: Address;
  amenities: Array<Amenity>;
  availabilityDate?: Maybe<Scalars['Datetime']>;
  bathRange?: Maybe<MinMaxFloatRange>;
  bedsRange?: Maybe<CommunityBedsRange>;
  contactEmail?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Media>;
  description: Scalars['String'];
  externalId: Scalars['ID'];
  floorPlans: Array<FloorPlan>;
  googlePlacesReviewUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  liabilityCoverage?: Maybe<Scalars['Int']>;
  listingStatus: CommunityListingStatusType;
  logoImagePublicId?: Maybe<Scalars['ID']>;
  media: Array<Media>;
  moveOutReasonOptions?: Maybe<Array<Maybe<MoveOutReasonOption>>>;
  name: Scalars['String'];
  noticeToVacateDeadlineDaysBeforeLeaseEnd?: Maybe<Scalars['Int']>;
  offeredStatus: CommunityOfferedStatusType;
  pointValue: Scalars['Int'];
  propertyManager: PropertyManager;
  rentRange?: Maybe<MinMaxFloatRange>;
  rentableItemCategories?: Maybe<Array<RentableItemCategory>>;
  tourLink?: Maybe<Scalars['String']>;
};

export type CommunityBathRange = {
  __typename?: 'CommunityBathRange';
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type CommunityBedsRange = {
  __typename?: 'CommunityBedsRange';
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export enum CommunityListingStatusType {
  Inactive = 'INACTIVE',
  Live = 'LIVE',
}

export type CommunityNotLive = {
  __typename?: 'CommunityNotLive';
  message?: Maybe<Scalars['String']>;
};

export enum CommunityOfferedStatusType {
  Inactive = 'INACTIVE',
  Live = 'LIVE',
}

export enum CommunityResidentType {
  Guarantor = 'GUARANTOR',
  Leaseholder = 'LEASEHOLDER',
  Occupant = 'OCCUPANT',
}

export type CommunityReview = {
  __typename?: 'CommunityReview';
  id: Scalars['ID'];
};

export type CommunityReviewDisabled = {
  __typename?: 'CommunityReviewDisabled';
  message: Scalars['String'];
};

export enum ConnectionType {
  Email = 'EMAIL',
  Sms = 'SMS',
}

export type DownloadableFileUrl = {
  __typename?: 'DownloadableFileUrl';
  url?: Maybe<Scalars['String']>;
};

export type DownloadableFileUrlResponse = DownloadableFileUrl | Error;

export type ESignature = {
  __typename?: 'ESignature';
  id: Scalars['ID'];
  signedUsers: Array<User>;
  status: ESignatureStatus;
  type: ESignatureType;
};

export type ESignatureEmbedPayload = {
  __typename?: 'ESignatureEmbedPayload';
  url?: Maybe<Scalars['String']>;
};

export type ESignatureEmbedResponseError = {
  __typename?: 'ESignatureEmbedResponseError';
  message: Scalars['String'];
};

export type ESignatureEmbedUrlResponse =
  | ESignatureEmbedPayload
  | ESignatureEmbedResponseError;

export enum ESignatureStatus {
  AllResidentsSigned = 'ALL_RESIDENTS_SIGNED',
  FullyExecuted = 'FULLY_EXECUTED',
  InitiatedNobodySigned = 'INITIATED_NOBODY_SIGNED',
  SomeResidentsSigned = 'SOME_RESIDENTS_SIGNED',
}

export enum ESignatureType {
  NoticeToVacate = 'NOTICE_TO_VACATE',
}

export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
};

export type Favorite = {
  __typename?: 'Favorite';
  active: Scalars['Boolean'];
  housing: Housing;
};

export type Feature = {
  __typename?: 'Feature';
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export enum FileObjectType {
  EsignedNoticeToVacate = 'ESIGNED_NOTICE_TO_VACATE',
  RoommateChangeForm = 'ROOMMATE_CHANGE_FORM',
}

export enum FileObjectTypeV2 {
  EsignedNoticeToVacate = 'ESIGNED_NOTICE_TO_VACATE',
  RoommateChangeForm = 'ROOMMATE_CHANGE_FORM',
}

export type FilterInput = {
  key: FilterKey;
  operator: FilterOperator;
  value: Scalars['Int'];
};

export enum FilterKey {
  Baths = 'BATHS',
  Beds = 'BEDS',
  Rent = 'RENT',
}

export enum FilterOperator {
  Exact = 'EXACT',
  Gt = 'GT',
  Gte = 'GTE',
  Lt = 'LT',
  Lte = 'LTE',
}

export type FloorPlan = {
  __typename?: 'FloorPlan';
  availabilityDate?: Maybe<Scalars['Datetime']>;
  availableUnitCount: Scalars['Int'];
  baths: Scalars['Float'];
  beds: Scalars['Int'];
  community: Community;
  coverImage?: Maybe<Media>;
  externalId: Scalars['ID'];
  id: Scalars['ID'];
  keyAmenities: KeyAmenities;
  mapImageId?: Maybe<Scalars['String']>;
  maxSqft: Scalars['Int'];
  media: Array<Media>;
  minSqft: Scalars['Int'];
  name: Scalars['String'];
  rentRange: MinMaxFloatRange;
  unitCount: Scalars['Int'];
  units: Array<Unit>;
};

export type FormAddressInput = {
  city: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  secondary?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type FormSingleSelectInput = {
  additionalInfo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  option: Scalars['String'];
};

export type ForwardingAddressInput = {
  address?: Maybe<FormAddressInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export enum FulfillmentStatus {
  Fulfilled = 'FULFILLED',
  NotFulfilled = 'NOT_FULFILLED',
}

export type FutureLease = {
  __typename?: 'FutureLease';
  signerStatus: SignerStatus;
  signingStatus: ESignatureStatus;
};

export type GeolocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  type: GeolocationType;
};

export enum GeolocationType {
  Locality = 'LOCALITY',
  Neighborhood = 'NEIGHBORHOOD',
  Sublocality = 'SUBLOCALITY',
}

export type Housing = Community | FloorPlan | Unit;

export enum HousingType {
  Community = 'COMMUNITY',
  FloorPlan = 'FLOOR_PLAN',
  Unit = 'UNIT',
}

export type ImportantDeadlines = {
  __typename?: 'ImportantDeadlines';
  lastDayForNoticeToVacate?: Maybe<Scalars['Datetime']>;
  lastDayToRenew?: Maybe<Scalars['Datetime']>;
  leaseEndDate?: Maybe<Scalars['Datetime']>;
  renewalSavingsEndOn?: Maybe<Scalars['Datetime']>;
};

export type Incentive = {
  __typename?: 'Incentive';
  balance: Scalars['Float'];
  redemptionDeadline?: Maybe<Scalars['Datetime']>;
  redemptions: Array<IncentiveRedemption>;
};

export type IncentiveRedemption = {
  __typename?: 'IncentiveRedemption';
  amount: Scalars['Int'];
};

export type InvalidLoginError = {
  __typename?: 'InvalidLoginError';
  message?: Maybe<Scalars['String']>;
};

export type InvalidOneTimePasswordRequestError = {
  __typename?: 'InvalidOneTimePasswordRequestError';
  message?: Maybe<Scalars['String']>;
};

export type InvalidRefreshToken = {
  __typename?: 'InvalidRefreshToken';
  message?: Maybe<Scalars['String']>;
};

export type KeyAmenities = {
  __typename?: 'KeyAmenities';
  garage: Scalars['Boolean'];
  onSiteLaundry: Scalars['Boolean'];
  patioBalcony: Scalars['Boolean'];
};

export type Lease = {
  __typename?: 'Lease';
  community: Community;
  currentRent: Scalars['Float'];
  externalId: Scalars['ID'];
  fromDate: Scalars['Datetime'];
  id: Scalars['ID'];
  monthToMonth: Scalars['Boolean'];
  moveOutInfo?: Maybe<MoveOutInfo>;
  noticeGivenDate?: Maybe<Scalars['Datetime']>;
  othersOnLease: Array<Resident>;
  pets: Scalars['String'];
  renewalOffer?: Maybe<RenewalOffer>;
  renewedOffline: Scalars['Boolean'];
  status: LeaseStatus;
  toDate?: Maybe<Scalars['Datetime']>;
  unit: Unit;
};

export type LeaseSigner = {
  __typename?: 'LeaseSigner';
  completed: Scalars['Boolean'];
};

export type LeaseSigningDetails = {
  __typename?: 'LeaseSigningDetails';
  propertyNumber: Scalars['String'];
  securityPin: Scalars['String'];
  signerId: Scalars['String'];
  token: Scalars['String'];
};

export enum LeaseStatus {
  Expired = 'EXPIRED',
  New = 'NEW',
  RenewalOffered = 'RENEWAL_OFFERED',
  Renewed = 'RENEWED',
}

export type Location = {
  __typename?: 'Location';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<GeolocationType>;
};

export type LocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  type: GeolocationType;
};

export type LoginAttributionInput = {
  campaign?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  attribution?: Maybe<LoginAttributionInput>;
  code: Scalars['String'];
  connection: ConnectionType;
  inviteToken: Scalars['String'];
  optedIntoSMS?: Maybe<Scalars['Boolean']>;
};

export type LoginResponse =
  | AuthenticationPayload
  | CommunityNotLive
  | InvalidLoginError
  | LoginServiceError;

export type LoginServiceError = {
  __typename?: 'LoginServiceError';
  message?: Maybe<Scalars['String']>;
};

export type MatchedFloorPlan = {
  __typename?: 'MatchedFloorPlan';
  floorPlan: FloorPlan;
  matchedUnitsCount: Scalars['Int'];
};

export type MatchingCommunitiesOutput = PaginationOutput & {
  __typename?: 'MatchingCommunitiesOutput';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  reason?: Maybe<MatchingCommunityResultReason>;
  results: Array<MatchingCommunity>;
  totalCount: Scalars['Int'];
};

export type MatchingCommunity = {
  __typename?: 'MatchingCommunity';
  community: Community;
  matchRanking: Scalars['Int'];
  matchedFloorPlans: Array<MatchedFloorPlan>;
  matchedUnitsCount: Scalars['Int'];
};

export enum MatchingCommunityResultReason {
  NoFilterMatches = 'NO_FILTER_MATCHES',
  NoGeolocationMatches = 'NO_GEOLOCATION_MATCHES',
}

export type Media = {
  __typename?: 'Media';
  active: Scalars['Boolean'];
  contentType: Scalars['String'];
  entityId: Scalars['ID'];
  entityType: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  fileDescription?: Maybe<Scalars['String']>;
  fileType: Scalars['String'];
  id: Scalars['ID'];
  objectId: Scalars['Int'];
  publicId: Scalars['String'];
};

export type MinMaxFloatRange = {
  __typename?: 'MinMaxFloatRange';
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type MinMaxFloatRangeInput = {
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type MoveOutInfo = {
  __typename?: 'MoveOutInfo';
  forwardingAddress?: Maybe<Address>;
  forwardingAddressFirstName?: Maybe<Scalars['String']>;
  forwardingAddressLastName?: Maybe<Scalars['String']>;
  moveOutDate?: Maybe<Scalars['Datetime']>;
  reasonForMoveOut: Scalars['String'];
  reasonForMoveOutAdditionalInfo?: Maybe<Scalars['String']>;
};

export type MoveOutReasonOption = {
  __typename?: 'MoveOutReasonOption';
  community_id: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptRenewalOffer: RenewalOfferDecisionResponse;
  createUserTaskResponse: UserTaskResponse;
  declineRenewalOffer: RenewalOfferDecisionResponse;
  login: LoginResponse;
  redeemIncentive: IncentiveRedemption;
  refreshAccessToken: RefreshAccessTokenResponse;
  removeUserTask: UserTaskRemovedResponse;
  resetAllRenewalOffers: Array<RenewalOffer>;
  sendOneTimePassword: SendOneTimePasswordResponse;
  submitCommunityReview: SubmitCommunityReviewResponse;
  submitHousingInterest: Housing;
  submitNeedSomethingElse: NeedSomethingElse;
  submitRentersInsuranceInfo: RentersInsuranceInfoResponse;
  toggleFavorite: Favorite;
  updateLeaseSigner: LeaseSigner;
  updateMoveOutInfo: MoveOutInfo;
  updateUser: User;
};

export type MutationAcceptRenewalOfferArgs = {
  input: AcceptRenewalOfferInput;
};

export type MutationCreateUserTaskResponseArgs = {
  input: UserTaskResponseInput;
};

export type MutationDeclineRenewalOfferArgs = {
  input?: Maybe<ResidentMoveOutInfoInput>;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationRefreshAccessTokenArgs = {
  input: RefreshAccessTokenInput;
};

export type MutationRemoveUserTaskArgs = {
  input: UserTaskRemovedInput;
};

export type MutationResetAllRenewalOffersArgs = {
  input?: Maybe<ResetRenewalOfferInput>;
};

export type MutationSendOneTimePasswordArgs = {
  input: SendOneTimePasswordInput;
};

export type MutationSubmitCommunityReviewArgs = {
  input: SubmitCommunityReviewInput;
};

export type MutationSubmitHousingInterestArgs = {
  input: SubmitHousingInterestInput;
};

export type MutationSubmitNeedSomethingElseArgs = {
  input: NeedSomethingElseInput;
};

export type MutationSubmitRentersInsuranceInfoArgs = {
  input: SubmitRentersInsuranceInfoInput;
};

export type MutationToggleFavoriteArgs = {
  input: ToggleFavoriteInput;
};

export type MutationUpdateLeaseSignerArgs = {
  input: UpdateLeaseSignerInput;
};

export type MutationUpdateMoveOutInfoArgs = {
  input: ResidentMoveOutInfoInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type NeedSomethingElse = {
  __typename?: 'NeedSomethingElse';
  additionalInfo: Scalars['String'];
  needs: Array<Scalars['String']>;
  preferredTerm?: Maybe<RenewalOfferPricingTerm>;
};

export type NeedSomethingElseInput = {
  additionalInfo: Scalars['String'];
  needs: Array<Scalars['String']>;
  preferredTermId?: Maybe<Scalars['ID']>;
};

export enum OccupancyStatusTypes {
  Occupied = 'OCCUPIED',
  Vacant = 'VACANT',
}

export type OnboardingLease = {
  __typename?: 'OnboardingLease';
  community: Community;
  externalId: Scalars['ID'];
  fromDate: Scalars['Datetime'];
  id: Scalars['ID'];
  monthToMonth: Scalars['Boolean'];
  renewalOffer?: Maybe<OnboardingRenewalOffer>;
  toDate?: Maybe<Scalars['Datetime']>;
  unit: OnboardingUnit;
};

export type OnboardingRenewalExpiry = {
  __typename?: 'OnboardingRenewalExpiry';
  days: Scalars['Int'];
  delta?: Maybe<Scalars['Datetime']>;
};

export type OnboardingRenewalOffer = {
  __typename?: 'OnboardingRenewalOffer';
  effectiveFrom?: Maybe<Scalars['Datetime']>;
  effectiveTo?: Maybe<Scalars['Datetime']>;
  status: RenewalOfferStatus;
  zeroPointTermPresent: Scalars['Boolean'];
};

export type OnboardingUnit = {
  __typename?: 'OnboardingUnit';
  baths: Scalars['Float'];
  beds: Scalars['Int'];
  community: Scalars['String'];
  name: Scalars['String'];
};

export type OnboardingUser = {
  __typename?: 'OnboardingUser';
  claimed: Scalars['Boolean'];
  currentLease: OnboardingLease;
  enabledFeatures: Array<Scalars['String']>;
  experiments: Array<UserExperiment>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  incentives: Incentive;
  inviteToken: Scalars['String'];
  maskedEmail: Scalars['String'];
  maskedPhone: Scalars['String'];
};

export type OnboardingUserResponse = OnboardingUser | UnknownOnboardingUser;

export type OneTimePassword = {
  __typename?: 'OneTimePassword';
  success: Scalars['Boolean'];
};

export type PaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PaginationOutput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export enum PricingType {
  Dynamic = 'DYNAMIC',
  Standard = 'STANDARD',
}

export type ProfileInput = {
  firstName?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type PropertyManager = {
  __typename?: 'PropertyManager';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  community?: Maybe<Community>;
  currentUser?: Maybe<User>;
  currentUserTasks: Array<UserTask>;
  downloadableFileUrl?: Maybe<DownloadableFileUrlResponse>;
  eSignatureEmbedUrl?: Maybe<ESignatureEmbedUrlResponse>;
  floorPlan?: Maybe<FloorPlan>;
  leaseSigningDetails?: Maybe<LeaseSigningDetails>;
  matchingCommunities?: Maybe<MatchingCommunitiesOutput>;
  onboardingUser?: Maybe<OnboardingUserResponse>;
};

export type QueryCommunityArgs = {
  id: Scalars['ID'];
};

export type QueryDownloadableFileUrlArgs = {
  objectId?: Maybe<Scalars['ID']>;
  objectType: FileObjectType;
};

export type QueryESignatureEmbedUrlArgs = {
  eSignedNoticeToVacateID: Scalars['ID'];
};

export type QueryFloorPlanArgs = {
  id: Scalars['ID'];
};

export type QueryMatchingCommunitiesArgs = {
  paginate?: Maybe<PaginationInput>;
  searchInput: SearchInput;
};

export type QueryOnboardingUserArgs = {
  token: Scalars['String'];
};

export type RefreshAccessTokenInput = {
  refreshToken: Scalars['String'];
};

export type RefreshAccessTokenResponse =
  | AuthenticationPayload
  | InvalidLoginError
  | InvalidRefreshToken;

export type RenewalOffer = {
  __typename?: 'RenewalOffer';
  decision?: Maybe<RenewalOfferDecision>;
  effectiveFrom: Scalars['Datetime'];
  effectiveTo: Scalars['Datetime'];
  id: Scalars['ID'];
  pricings: RenewalOfferPricings;
  status: RenewalOfferStatus;
  zeroPointTermPresent: Scalars['Boolean'];
};

export type RenewalOfferDecision = {
  __typename?: 'RenewalOfferDecision';
  decidedAt: Scalars['Datetime'];
  decidedByUser: User;
  decision: RenewalOfferDecisionType;
  eSignature?: Maybe<ESignature>;
  id: Scalars['ID'];
  interestedTerm?: Maybe<RenewalOfferPricingTerm>;
  pricing: RenewalOfferPricing;
};

export type RenewalOfferDecisionError = {
  __typename?: 'RenewalOfferDecisionError';
  message?: Maybe<Scalars['String']>;
};

export type RenewalOfferDecisionExpiredNonExistent = {
  __typename?: 'RenewalOfferDecisionExpiredNonExistent';
  message?: Maybe<Scalars['String']>;
};

export type RenewalOfferDecisionMade = {
  __typename?: 'RenewalOfferDecisionMade';
  message?: Maybe<Scalars['String']>;
};

export type RenewalOfferDecisionResponse =
  | RenewalOffer
  | RenewalOfferDecisionError
  | RenewalOfferDecisionExpiredNonExistent
  | RenewalOfferDecisionMade;

export enum RenewalOfferDecisionType {
  Declined = 'DECLINED',
  Interested = 'INTERESTED',
  InterestedLate = 'INTERESTED_LATE',
}

export type RenewalOfferPricing = {
  __typename?: 'RenewalOfferPricing';
  createdAt: Scalars['Datetime'];
  effectiveFrom: Scalars['Datetime'];
  effectiveTo: Scalars['Datetime'];
  id: Scalars['ID'];
  terms: Array<RenewalOfferPricingTerm>;
};

export type RenewalOfferPricingTerm = {
  __typename?: 'RenewalOfferPricingTerm';
  baseRent: Scalars['Float'];
  charges?: Maybe<Array<Maybe<Charge>>>;
  id: Scalars['ID'];
  leaseEndDate?: Maybe<Scalars['Datetime']>;
  leaseStartDate?: Maybe<Scalars['Datetime']>;
  lengthInMonths: Scalars['Int'];
  pointValue: Scalars['Int'];
  securityDeposit?: Maybe<Scalars['Float']>;
  totalMonthlyCharge: Scalars['Float'];
};

export type RenewalOfferPricings = {
  __typename?: 'RenewalOfferPricings';
  current: RenewalOfferPricing;
  future?: Maybe<Array<Maybe<RenewalOfferPricing>>>;
  past?: Maybe<Array<Maybe<RenewalOfferPricing>>>;
};

export enum RenewalOfferStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Interested = 'INTERESTED',
  InterestedLate = 'INTERESTED_LATE',
  NotOffered = 'NOT_OFFERED',
  Offered = 'OFFERED',
}

export type RentRange = {
  __typename?: 'RentRange';
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type RentableItemCategory = {
  __typename?: 'RentableItemCategory';
  amountRange: MinMaxFloatRange;
  category: Scalars['String'];
};

export type RentableItemCategoryInput = {
  amountRange: MinMaxFloatRangeInput;
  category: Scalars['String'];
};

export type RentersInsuranceInfo = {
  __typename?: 'RentersInsuranceInfo';
  documentUrl: Scalars['String'];
  id: Scalars['ID'];
  liabilityCoverage: Scalars['Int'];
  policyEndDate: Scalars['Date'];
  policyStartDate: Scalars['Date'];
  provider: Scalars['String'];
};

export type RentersInsuranceInfoResponse = Error | RentersInsuranceInfo;

export type ResetRenewalOfferInput = {
  pricingType?: Maybe<PricingType>;
  rewards?: Maybe<Scalars['Boolean']>;
};

export type Resident = {
  __typename?: 'Resident';
  cellPhone?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  homePhone?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  residentType: CommunityResidentType;
  status?: Maybe<ResidentStatus>;
};

export type ResidentInfoInput = {
  additionalInfo?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  homePhone?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  roommateInfo?: Maybe<Scalars['String']>;
};

export type ResidentInput = {
  id: Scalars['ID'];
};

export type ResidentMoveOutInfoInput = {
  forwardingAddress?: Maybe<ForwardingAddressInput>;
  moveOutDate?: Maybe<Scalars['Datetime']>;
  noticeToVacateFlow: Scalars['Boolean'];
  reasonForMoveOut: FormSingleSelectInput;
};

export enum ResidentStatus {
  Applicant = 'APPLICANT',
  Cancelled = 'CANCELLED',
  Current = 'CURRENT',
  Evicted = 'EVICTED',
  Future = 'FUTURE',
  Notice = 'NOTICE',
  Past = 'PAST',
}

export type SearchInput = {
  filterString?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<FilterInput>>;
  geolocation?: Maybe<GeolocationInput>;
  optionalFilterStrings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SendCodeError = {
  __typename?: 'SendCodeError';
  message?: Maybe<Scalars['String']>;
};

export type SendOneTimePasswordInput = {
  connection: ConnectionType;
  inviteToken: Scalars['String'];
};

export type SendOneTimePasswordResponse =
  | CommunityNotLive
  | InvalidOneTimePasswordRequestError
  | OneTimePassword
  | SendCodeError
  | UserNotFound;

export enum SignerStatus {
  Requested = 'REQUESTED',
  Signed = 'SIGNED',
}

export type SubmitCommunityReviewInput = {
  body: Scalars['String'];
  rating: Scalars['Int'];
};

export type SubmitCommunityReviewResponse =
  | CommunityReview
  | CommunityReviewDisabled;

export type SubmitHousingInterestInput = {
  housingId: Scalars['ID'];
  housingType: HousingType;
};

export type SubmitRentersInsuranceInfoInput = {
  document: Scalars['Upload'];
  liabilityCoverage: Scalars['Int'];
  policyEndDate: Scalars['Date'];
  policyStartDate: Scalars['Date'];
  provider: Scalars['String'];
};

export enum TaskTemplate {
  Acknowledgment = 'ACKNOWLEDGMENT',
  CtaWithCheckbox = 'CTA_WITH_CHECKBOX',
  DownloadFile = 'DOWNLOAD_FILE',
  EmbeddedTypeform = 'EMBEDDED_TYPEFORM',
  SimpleCta = 'SIMPLE_CTA',
}

export type ToggleFavoriteInput = {
  housingId: Scalars['ID'];
  housingType: HousingType;
};

export type Unit = {
  __typename?: 'Unit';
  address: Address;
  amenities: Array<Amenity>;
  availabilityDate?: Maybe<Scalars['Datetime']>;
  baths: Scalars['Float'];
  beds: Scalars['Int'];
  community: Community;
  coverImage?: Maybe<Media>;
  externalId: Scalars['ID'];
  floorPlan: FloorPlan;
  id: Scalars['ID'];
  keyAmenities: KeyAmenities;
  maxSqft: Scalars['Int'];
  media: Array<Media>;
  minSqft: Scalars['Int'];
  name: Scalars['String'];
  occupancyStatus?: Maybe<OccupancyStatusTypes>;
  pointValue: Scalars['Int'];
  rent: Scalars['Float'];
  securityDeposit?: Maybe<Scalars['Float']>;
};

export type UnitUpgrade = {
  __typename?: 'UnitUpgrade';
  createdAt: Scalars['Datetime'];
  unit: Unit;
};

export type UnknownOnboardingUser = {
  __typename?: 'UnknownOnboardingUser';
  message?: Maybe<Scalars['String']>;
};

export type UpdateLeaseSignerInput = {
  signerStatus: SignerStatus;
};

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  userPreferences?: Maybe<UserPreferencesInput>;
};

export enum UpgradeUnitStatus {
  Accepted = 'ACCEPTED',
  Interested = 'INTERESTED',
  NotOffered = 'NOT_OFFERED',
  Offered = 'OFFERED',
}

export type User = {
  __typename?: 'User';
  cellPhone?: Maybe<Scalars['String']>;
  claimed: Scalars['Boolean'];
  communityLogoId?: Maybe<Scalars['String']>;
  currentLease: Lease;
  daysToLeaseExpire?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  enabledFeatures: Array<Scalars['String']>;
  experiments: Array<UserExperiment>;
  favorites: Array<Favorite>;
  firstName: Scalars['String'];
  futureLease?: Maybe<FutureLease>;
  hasCommunityReview: Scalars['Boolean'];
  hasRentersInsurance: Scalars['Boolean'];
  homePhone?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importantDeadlines: ImportantDeadlines;
  incentives: Incentive;
  interestedHousing: Array<Housing>;
  inviteToken: Scalars['String'];
  lastName: Scalars['String'];
  needsToEsignNoticeToVacate: Scalars['Boolean'];
  preferences: UserPreference;
  rentersInsurance: Array<RentersInsuranceInfo>;
  resident: Resident;
  unitUpgrade?: Maybe<UnitUpgrade>;
  upgradeUnitStatus: UpgradeUnitStatus;
  upgradeableUnits: Array<Unit>;
};

export type UserExperiment = {
  __typename?: 'UserExperiment';
  experimentConfig: Scalars['JSON'];
  experimentName: Scalars['String'];
};

export type UserIdentity = {
  __typename?: 'UserIdentity';
  idpUserId: Scalars['String'];
};

export type UserNotFound = {
  __typename?: 'UserNotFound';
  message?: Maybe<Scalars['String']>;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  amenities: Array<Scalars['String']>;
  location?: Maybe<Location>;
  maxBudget?: Maybe<Scalars['Int']>;
  minBath?: Maybe<Scalars['Int']>;
  minBed?: Maybe<Scalars['Int']>;
  minBudget?: Maybe<Scalars['Int']>;
};

export type UserPreferencesInput = {
  amenities?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<LocationInput>;
  maxBudget?: Maybe<Scalars['Int']>;
  minBath?: Maybe<Scalars['Int']>;
  minBed?: Maybe<Scalars['Int']>;
  minBudget?: Maybe<Scalars['Int']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  firstName?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type UserTask = {
  __typename?: 'UserTask';
  autoFulfillment: Scalars['Boolean'];
  customTemplateContext?: Maybe<Scalars['JSON']>;
  displayTitle: Scalars['String'];
  dueDate?: Maybe<Scalars['Datetime']>;
  explanatoryText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  primaryLease: Lease;
  required: Scalars['Boolean'];
  status: FulfillmentStatus;
  taskTemplate: TaskTemplate;
  user: User;
  userRemovable: Scalars['Boolean'];
};

export type UserTaskRemovedInput = {
  userTaskId: Scalars['ID'];
};

export type UserTaskRemovedResponse = {
  __typename?: 'UserTaskRemovedResponse';
  userTaskId: Scalars['ID'];
};

export type UserTaskResponse = {
  __typename?: 'UserTaskResponse';
  response?: Maybe<Scalars['JSON']>;
  userTaskId: Scalars['ID'];
  userTaskTemplate: TaskTemplate;
};

export type UserTaskResponseInput = {
  response?: Maybe<Scalars['JSON']>;
  userTaskId: Scalars['ID'];
  userTaskTemplate: TaskTemplate;
};

export type AddressFragment = { __typename?: 'Address' } & Pick<
  Address,
  'city' | 'secondary' | 'state' | 'street' | 'zipCode' | 'id'
>;

export type ChargeFragment = { __typename?: 'Charge' } & Pick<
  Charge,
  'id' | 'name' | 'amount' | 'frequency' | 'type'
>;

export type CommunityFragment = { __typename?: 'Community' } & Pick<
  Community,
  | 'id'
  | 'name'
  | 'description'
  | 'pointValue'
  | 'tourLink'
  | 'liabilityCoverage'
> & {
    propertyManager: { __typename?: 'PropertyManager' } & Pick<
      PropertyManager,
      'name' | 'id'
    >;
    address: { __typename?: 'Address' } & GeolocatedAddressFragment;
    media: Array<{ __typename?: 'Media' } & MediaFragment>;
    amenities: Array<{ __typename?: 'Amenity' } & Pick<Amenity, 'description'>>;
    floorPlans: Array<{ __typename?: 'FloorPlan' } & FloorPlanSummaryFragment>;
    rentableItemCategories?: Maybe<
      Array<
        { __typename?: 'RentableItemCategory' } & RentableItemCategoryFragment
      >
    >;
  };

export type CommunitySummaryFragment = { __typename?: 'Community' } & Pick<
  Community,
  'id' | 'name' | 'pointValue' | 'availabilityDate' | 'tourLink'
> & {
    propertyManager: { __typename?: 'PropertyManager' } & Pick<
      PropertyManager,
      'id' | 'name'
    >;
    address: { __typename?: 'Address' } & GeolocatedAddressFragment;
    rentRange?: Maybe<
      { __typename?: 'MinMaxFloatRange' } & Pick<
        MinMaxFloatRange,
        'min' | 'max'
      >
    >;
    bathRange?: Maybe<
      { __typename?: 'MinMaxFloatRange' } & Pick<
        MinMaxFloatRange,
        'min' | 'max'
      >
    >;
    bedsRange?: Maybe<
      { __typename?: 'CommunityBedsRange' } & Pick<
        CommunityBedsRange,
        'min' | 'max'
      >
    >;
    coverImage?: Maybe<{ __typename?: 'Media' } & MediaFragment>;
  };

export type CurrentCommunityFragment = { __typename?: 'Community' } & Pick<
  Community,
  | 'id'
  | 'name'
  | 'noticeToVacateDeadlineDaysBeforeLeaseEnd'
  | 'googlePlacesReviewUrl'
> & {
    address: { __typename?: 'Address' } & GeolocatedAddressFragment;
    propertyManager: { __typename?: 'PropertyManager' } & Pick<
      PropertyManager,
      'name' | 'id'
    >;
    moveOutReasonOptions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'MoveOutReasonOption' } & Pick<
            MoveOutReasonOption,
            'id' | 'community_id' | 'description'
          >
        >
      >
    >;
  };

export type CurrentFavoritesFragment = { __typename?: 'User' } & {
  favorites: Array<
    { __typename?: 'Favorite' } & Pick<Favorite, 'active'> & {
        housing:
          | ({ __typename?: 'Community' } & CommunitySummaryFragment)
          | { __typename?: 'FloorPlan' }
          | { __typename?: 'Unit' };
      }
  >;
};

export type EnabledFeaturesFragment = { __typename?: 'User' } & Pick<
  User,
  'enabledFeatures'
>;

export type FloorPlanFragment = { __typename?: 'FloorPlan' } & Pick<
  FloorPlan,
  | 'id'
  | 'availabilityDate'
  | 'beds'
  | 'baths'
  | 'name'
  | 'mapImageId'
  | 'maxSqft'
> & {
    community: { __typename?: 'Community' } & Pick<Community, 'id'>;
    floorPlanRange: { __typename?: 'MinMaxFloatRange' } & Pick<
      MinMaxFloatRange,
      'min' | 'max'
    >;
    keyAmenities: { __typename?: 'KeyAmenities' } & KeyAmenitiesFragment;
    media: Array<{ __typename?: 'Media' } & MediaFragment>;
  };

export type FloorPlanSummaryFragment = { __typename?: 'FloorPlan' } & Pick<
  FloorPlan,
  | 'id'
  | 'name'
  | 'beds'
  | 'baths'
  | 'unitCount'
  | 'availableUnitCount'
  | 'minSqft'
  | 'maxSqft'
  | 'availabilityDate'
  | 'mapImageId'
> & {
    floorPlanRange: { __typename?: 'MinMaxFloatRange' } & Pick<
      MinMaxFloatRange,
      'min' | 'max'
    >;
  };

export type FutureLeaseFragment = { __typename?: 'FutureLease' } & Pick<
  FutureLease,
  'signerStatus' | 'signingStatus'
>;

export type GeolocatedAddressFragment = { __typename?: 'Address' } & Pick<
  Address,
  | 'city'
  | 'secondary'
  | 'state'
  | 'street'
  | 'zipCode'
  | 'latitude'
  | 'longitude'
  | 'id'
>;

type Housing_Community_Fragment = {
  __typename?: 'Community';
} & CommunitySummaryFragment;

type Housing_FloorPlan_Fragment = {
  __typename?: 'FloorPlan';
} & FloorPlanFragment;

type Housing_Unit_Fragment = { __typename?: 'Unit' } & UnitFragment;

export type HousingFragment =
  | Housing_Community_Fragment
  | Housing_FloorPlan_Fragment
  | Housing_Unit_Fragment;

export type ImportantDeadlinesFragment = {
  __typename?: 'ImportantDeadlines';
} & Pick<
  ImportantDeadlines,
  'lastDayToRenew' | 'renewalSavingsEndOn' | 'leaseEndDate'
>;

export type IncentiveFragment = { __typename?: 'Incentive' } & Pick<
  Incentive,
  'balance' | 'redemptionDeadline'
> & {
    redemptions: Array<
      { __typename?: 'IncentiveRedemption' } & IncentiveRedemptionFragment
    >;
  };

export type IncentiveRedemptionFragment = {
  __typename?: 'IncentiveRedemption';
} & Pick<IncentiveRedemption, 'amount'>;

export type KeyAmenitiesFragment = { __typename?: 'KeyAmenities' } & Pick<
  KeyAmenities,
  'onSiteLaundry' | 'garage' | 'patioBalcony'
>;

export type LeaseFragment = { __typename?: 'Lease' } & Pick<
  Lease,
  | 'id'
  | 'currentRent'
  | 'fromDate'
  | 'toDate'
  | 'status'
  | 'monthToMonth'
  | 'renewedOffline'
  | 'noticeGivenDate'
> & {
    unit: { __typename?: 'Unit' } & UnitFragment;
    othersOnLease: Array<{ __typename?: 'Resident' } & ResidentFragment>;
    moveOutInfo?: Maybe<{ __typename?: 'MoveOutInfo' } & MoveOutInfoFragment>;
  };

export type LeaseSigningDetailsFragment = {
  __typename?: 'LeaseSigningDetails';
} & Pick<
  LeaseSigningDetails,
  'token' | 'propertyNumber' | 'signerId' | 'securityPin'
>;

export type LocationFragment = { __typename?: 'Location' } & Pick<
  Location,
  'latitude' | 'longitude' | 'name' | 'type'
>;

export type MatchingCommunitiesOutputFragment = {
  __typename?: 'MatchingCommunitiesOutput';
} & Pick<MatchingCommunitiesOutput, 'reason' | 'totalCount'> & {
    results: Array<
      { __typename?: 'MatchingCommunity' } & MatchingCommunityFragment
    >;
  };

export type MatchingCommunityFragment = {
  __typename?: 'MatchingCommunity';
} & Pick<MatchingCommunity, 'matchedUnitsCount' | 'matchRanking'> & {
    community: { __typename?: 'Community' } & CommunitySummaryFragment;
  };

export type MediaFragment = { __typename?: 'Media' } & Pick<Media, 'publicId'>;

export type MinMaxFloatRangeFragment = {
  __typename?: 'MinMaxFloatRange';
} & Pick<MinMaxFloatRange, 'min' | 'max'>;

export type MoveOutInfoFragment = { __typename?: 'MoveOutInfo' } & Pick<
  MoveOutInfo,
  | 'moveOutDate'
  | 'forwardingAddressFirstName'
  | 'forwardingAddressLastName'
  | 'reasonForMoveOut'
  | 'reasonForMoveOutAdditionalInfo'
> & { forwardingAddress?: Maybe<{ __typename?: 'Address' } & AddressFragment> };

export type OnboardingCommunityFragment = { __typename?: 'Community' } & Pick<
  Community,
  'id' | 'name'
> & {
    propertyManager: { __typename?: 'PropertyManager' } & Pick<
      PropertyManager,
      'name' | 'id'
    >;
  };

export type OnboardingLeaseFragment = { __typename?: 'OnboardingLease' } & Pick<
  OnboardingLease,
  'id' | 'externalId' | 'fromDate' | 'toDate'
> & {
    renewalOffer?: Maybe<
      { __typename?: 'OnboardingRenewalOffer' } & OnboardingRenewalOfferFragment
    >;
    unit: { __typename?: 'OnboardingUnit' } & OnboardingUnitFragment;
    community: { __typename?: 'Community' } & OnboardingCommunityFragment;
  };

export type OnboardingRenewalOfferFragment = {
  __typename?: 'OnboardingRenewalOffer';
} & Pick<
  OnboardingRenewalOffer,
  'effectiveTo' | 'effectiveFrom' | 'status' | 'zeroPointTermPresent'
>;

export type OnboardingUnitFragment = { __typename?: 'OnboardingUnit' } & Pick<
  OnboardingUnit,
  'beds' | 'baths' | 'name' | 'community'
>;

export type OnboardingUserFragment = { __typename?: 'OnboardingUser' } & Pick<
  OnboardingUser,
  | 'id'
  | 'claimed'
  | 'firstName'
  | 'inviteToken'
  | 'maskedEmail'
  | 'maskedPhone'
  | 'enabledFeatures'
> & {
    experiments: Array<
      { __typename?: 'UserExperiment' } & UserExperimentFragment
    >;
    incentives: { __typename?: 'Incentive' } & IncentiveFragment;
    currentLease: { __typename?: 'OnboardingLease' } & OnboardingLeaseFragment;
  };

export type RenewalDataFragment = { __typename?: 'User' } & {
  incentives: { __typename?: 'Incentive' } & IncentiveFragment;
};

export type RenewalOfferFragment = { __typename?: 'RenewalOffer' } & Pick<
  RenewalOffer,
  'id' | 'status' | 'effectiveFrom' | 'effectiveTo' | 'zeroPointTermPresent'
> & {
    decision?: Maybe<
      { __typename?: 'RenewalOfferDecision' } & RenewalOfferDecisionFragment
    >;
    pricings: {
      __typename?: 'RenewalOfferPricings';
    } & RenewalOfferPricingsFragment;
  };

export type RenewalOfferDecisionFragment = {
  __typename?: 'RenewalOfferDecision';
} & Pick<RenewalOfferDecision, 'id' | 'decidedAt' | 'decision'> & {
    decidedByUser: { __typename?: 'User' } & UserSummaryFragment;
    interestedTerm?: Maybe<
      {
        __typename?: 'RenewalOfferPricingTerm';
      } & RenewalOfferPricingTermFragment
    >;
    pricing: {
      __typename?: 'RenewalOfferPricing';
    } & RenewalOfferPricingFragment;
    eSignature?: Maybe<
      { __typename?: 'ESignature' } & Pick<ESignature, 'id' | 'status'> & {
          signedUsers: Array<{ __typename?: 'User' } & Pick<User, 'id'>>;
        }
    >;
  };

export type RenewalOfferPricingFragment = {
  __typename?: 'RenewalOfferPricing';
} & Pick<
  RenewalOfferPricing,
  'id' | 'createdAt' | 'effectiveFrom' | 'effectiveTo'
> & {
    terms: Array<
      {
        __typename?: 'RenewalOfferPricingTerm';
      } & RenewalOfferPricingTermFragment
    >;
  };

export type RenewalOfferPricingsFragment = {
  __typename?: 'RenewalOfferPricings';
} & {
  past?: Maybe<
    Array<
      Maybe<
        { __typename?: 'RenewalOfferPricing' } & RenewalOfferPricingFragment
      >
    >
  >;
  current: { __typename?: 'RenewalOfferPricing' } & RenewalOfferPricingFragment;
  future?: Maybe<
    Array<
      Maybe<
        { __typename?: 'RenewalOfferPricing' } & RenewalOfferPricingFragment
      >
    >
  >;
};

export type RenewalOfferPricingTermFragment = {
  __typename?: 'RenewalOfferPricingTerm';
} & Pick<
  RenewalOfferPricingTerm,
  | 'id'
  | 'lengthInMonths'
  | 'baseRent'
  | 'securityDeposit'
  | 'pointValue'
  | 'leaseEndDate'
  | 'leaseStartDate'
  | 'totalMonthlyCharge'
> & {
    charges?: Maybe<Array<Maybe<{ __typename?: 'Charge' } & ChargeFragment>>>;
  };

export type RentableItemCategoryFragment = {
  __typename?: 'RentableItemCategory';
} & Pick<RentableItemCategory, 'category'> & {
    amountRange: { __typename?: 'MinMaxFloatRange' } & MinMaxFloatRangeFragment;
  };

export type ResidentFragment = { __typename?: 'Resident' } & Pick<
  Resident,
  'id' | 'firstName' | 'lastName' | 'emailAddress' | 'cellPhone' | 'status'
>;

export type UnitFragment = { __typename?: 'Unit' } & Pick<
  Unit,
  'id' | 'beds' | 'baths' | 'name'
> & {
    address: { __typename?: 'Address' } & AddressFragment;
    coverImage?: Maybe<{ __typename?: 'Media' } & MediaFragment>;
    media: Array<{ __typename?: 'Media' } & MediaFragment>;
    community: { __typename?: 'Community' } & Pick<
      Community,
      'id' | 'name' | 'liabilityCoverage'
    > & {
        address: { __typename?: 'Address' } & GeolocatedAddressFragment;
        propertyManager: { __typename?: 'PropertyManager' } & Pick<
          PropertyManager,
          'name'
        >;
      };
  };

export type UnitUpgradeFragment = { __typename?: 'UnitUpgrade' } & Pick<
  UnitUpgrade,
  'createdAt'
> & { unit: { __typename?: 'Unit' } & UnitFragment };

export type UpgradeDataFragment = { __typename?: 'User' } & Pick<
  User,
  'id' | 'upgradeUnitStatus'
> & {
    upgradeableUnits: Array<{ __typename?: 'Unit' } & UpgradeUnitFragment>;
    currentLease: { __typename?: 'Lease' } & Pick<Lease, 'id'> & {
        renewalOffer?: Maybe<
          { __typename?: 'RenewalOffer' } & Pick<
            RenewalOffer,
            'id' | 'effectiveTo'
          >
        >;
      };
    incentives: { __typename?: 'Incentive' } & IncentiveFragment;
    unitUpgrade?: Maybe<
      { __typename?: 'UnitUpgrade' } & Pick<UnitUpgrade, 'createdAt'> & {
          unit: { __typename?: 'Unit' } & UpgradeUnitFragment;
        }
    >;
  };

export type UpgradeUnitFragment = { __typename?: 'Unit' } & Pick<
  Unit,
  | 'id'
  | 'name'
  | 'beds'
  | 'baths'
  | 'minSqft'
  | 'maxSqft'
  | 'rent'
  | 'securityDeposit'
  | 'pointValue'
  | 'availabilityDate'
> & {
    address: { __typename?: 'Address' } & AddressFragment;
    media: Array<{ __typename?: 'Media' } & MediaFragment>;
    community: { __typename?: 'Community' } & Pick<Community, 'id' | 'name'> & {
        propertyManager: { __typename?: 'PropertyManager' } & Pick<
          PropertyManager,
          'name'
        >;
        address: { __typename?: 'Address' } & GeolocatedAddressFragment;
      };
    keyAmenities: { __typename?: 'KeyAmenities' } & Pick<
      KeyAmenities,
      'onSiteLaundry' | 'garage' | 'patioBalcony'
    >;
    floorPlan: { __typename?: 'FloorPlan' } & Pick<FloorPlan, 'mapImageId'>;
  };

export type UserFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'cellPhone'
  | 'homePhone'
  | 'claimed'
  | 'communityLogoId'
  | 'email'
  | 'firstName'
  | 'hasRentersInsurance'
  | 'inviteToken'
  | 'lastName'
  | 'upgradeUnitStatus'
  | 'enabledFeatures'
  | 'hasCommunityReview'
  | 'needsToEsignNoticeToVacate'
> & {
    experiments: Array<
      { __typename?: 'UserExperiment' } & UserExperimentFragment
    >;
    currentLease: { __typename?: 'Lease' } & {
      community: { __typename?: 'Community' } & CurrentCommunityFragment;
      renewalOffer?: Maybe<
        { __typename?: 'RenewalOffer' } & RenewalOfferFragment
      >;
    } & LeaseFragment;
    favorites: Array<
      { __typename?: 'Favorite' } & Pick<Favorite, 'active'> & {
          housing:
            | ({ __typename?: 'Community' } & CommunitySummaryFragment)
            | { __typename?: 'FloorPlan' }
            | { __typename?: 'Unit' };
        }
    >;
    unitUpgrade?: Maybe<
      { __typename?: 'UnitUpgrade' } & Pick<UnitUpgrade, 'createdAt'> & {
          unit: { __typename?: 'Unit' } & UpgradeUnitFragment;
        }
    >;
    rentersInsurance: Array<
      { __typename?: 'RentersInsuranceInfo' } & Pick<
        RentersInsuranceInfo,
        'id' | 'provider' | 'policyStartDate' | 'policyEndDate'
      >
    >;
    preferences: { __typename?: 'UserPreference' } & UserPreferenceFragment;
    incentives: { __typename?: 'Incentive' } & IncentiveFragment;
    resident: { __typename?: 'Resident' } & ResidentFragment;
    importantDeadlines: {
      __typename?: 'ImportantDeadlines';
    } & ImportantDeadlinesFragment;
  };

export type UserExperimentFragment = { __typename?: 'UserExperiment' } & Pick<
  UserExperiment,
  'experimentName' | 'experimentConfig'
>;

export type UserPreferenceFragment = { __typename?: 'UserPreference' } & Pick<
  UserPreference,
  'minBed' | 'minBath' | 'minBudget' | 'maxBudget' | 'amenities'
> & { location?: Maybe<{ __typename?: 'Location' } & LocationFragment> };

export type UserSummaryFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'cellPhone'
  | 'homePhone'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'communityLogoId'
  | 'inviteToken'
> & { resident: { __typename?: 'Resident' } & Pick<Resident, 'id' | 'status'> };

export type UserTaskFragment = { __typename?: 'UserTask' } & Pick<
  UserTask,
  | 'id'
  | 'taskTemplate'
  | 'customTemplateContext'
  | 'status'
  | 'displayTitle'
  | 'dueDate'
  | 'explanatoryText'
  | 'userRemovable'
  | 'required'
>;

export type InterestedHousingFragment = { __typename?: 'User' } & {
  interestedHousing: Array<
    | ({ __typename?: 'Community' } & CommunitySummaryFragment)
    | { __typename?: 'FloorPlan' }
    | { __typename?: 'Unit' }
  >;
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = { __typename?: 'Mutation' } & {
  login:
    | ({ __typename: 'AuthenticationPayload' } & Pick<
        AuthenticationPayload,
        'accessToken' | 'refreshToken'
      > & { currentUser: { __typename?: 'User' } & UserFragment })
    | ({ __typename: 'CommunityNotLive' } & Pick<CommunityNotLive, 'message'>)
    | { __typename?: 'InvalidLoginError' }
    | ({ __typename: 'LoginServiceError' } & Pick<
        LoginServiceError,
        'message'
      >);
};

export type RefreshAccessTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type RefreshAccessTokenMutation = { __typename?: 'Mutation' } & {
  refreshAccessToken:
    | ({ __typename: 'AuthenticationPayload' } & Pick<
        AuthenticationPayload,
        'accessToken' | 'refreshToken'
      > & { currentUser: { __typename?: 'User' } & UserFragment })
    | { __typename?: 'InvalidLoginError' }
    | ({ __typename: 'InvalidRefreshToken' } & Pick<
        InvalidRefreshToken,
        'message'
      >);
};

export type SendOneTimePasswordMutationVariables = Exact<{
  input: SendOneTimePasswordInput;
}>;

export type SendOneTimePasswordMutation = { __typename?: 'Mutation' } & {
  sendOneTimePassword:
    | ({ __typename: 'CommunityNotLive' } & Pick<CommunityNotLive, 'message'>)
    | { __typename: 'InvalidOneTimePasswordRequestError' }
    | ({ __typename: 'OneTimePassword' } & Pick<OneTimePassword, 'success'>)
    | ({ __typename: 'SendCodeError' } & Pick<SendCodeError, 'message'>)
    | ({ __typename: 'UserNotFound' } & Pick<UserNotFound, 'message'>);
};

export type SubmitCommunityReviewMutationVariables = Exact<{
  rating: Scalars['Int'];
  body: Scalars['String'];
}>;

export type SubmitCommunityReviewMutation = { __typename?: 'Mutation' } & {
  submitCommunityReview:
    | ({ __typename: 'CommunityReview' } & Pick<CommunityReview, 'id'>)
    | ({ __typename: 'CommunityReviewDisabled' } & Pick<
        CommunityReviewDisabled,
        'message'
      >);
};

export type SubmitHousingInterestMutationVariables = Exact<{
  housingId: Scalars['ID'];
  housingType: HousingType;
}>;

export type SubmitHousingInterestMutation = { __typename?: 'Mutation' } & {
  submitHousingInterest:
    | ({ __typename?: 'Community' } & CommunitySummaryFragment)
    | { __typename?: 'FloorPlan' }
    | { __typename?: 'Unit' };
};

export type RedeemIncentiveMutationVariables = Exact<{ [key: string]: never }>;

export type RedeemIncentiveMutation = { __typename?: 'Mutation' } & {
  redeemIncentive: {
    __typename?: 'IncentiveRedemption';
  } & IncentiveRedemptionFragment;
};

export type UpdateLeaseSignerMutationVariables = Exact<{
  input: UpdateLeaseSignerInput;
}>;

export type UpdateLeaseSignerMutation = { __typename?: 'Mutation' } & {
  updateLeaseSigner: { __typename?: 'LeaseSigner' } & Pick<
    LeaseSigner,
    'completed'
  >;
};

export type UpdateUserMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  userPreferences?: Maybe<UserPreferencesInput>;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser: { __typename?: 'User' } & UserFragment;
};

export type AcceptRenewalOfferMutationVariables = Exact<{
  renewalOfferPricingTermId: Scalars['ID'];
  residentInfo: ResidentInfoInput;
  addedRentableItems?: Maybe<
    Array<Maybe<RentableItemCategoryInput>> | Maybe<RentableItemCategoryInput>
  >;
  removedRentableItems?: Maybe<Array<Maybe<ChargeInput>> | Maybe<ChargeInput>>;
  totalMonthlyChargeRange?: Maybe<MinMaxFloatRangeInput>;
  removedResidents?: Maybe<Array<ResidentInput> | ResidentInput>;
  addedResidents?: Maybe<Scalars['Boolean']>;
}>;

export type AcceptRenewalOfferMutation = { __typename?: 'Mutation' } & {
  acceptRenewalOffer:
    | ({ __typename?: 'RenewalOffer' } & RenewalOfferFragment)
    | { __typename?: 'RenewalOfferDecisionError' }
    | ({ __typename: 'RenewalOfferDecisionExpiredNonExistent' } & Pick<
        RenewalOfferDecisionExpiredNonExistent,
        'message'
      >)
    | ({ __typename: 'RenewalOfferDecisionMade' } & Pick<
        RenewalOfferDecisionMade,
        'message'
      >);
};

export type DeclineRenewalOfferMutationVariables = Exact<{
  forwardingAddress?: Maybe<ForwardingAddressInput>;
  moveOutDate?: Maybe<Scalars['Datetime']>;
  reasonForMoveOut: FormSingleSelectInput;
  noticeToVacateFlow: Scalars['Boolean'];
}>;

export type DeclineRenewalOfferMutation = { __typename?: 'Mutation' } & {
  declineRenewalOffer:
    | ({ __typename?: 'RenewalOffer' } & RenewalOfferFragment)
    | { __typename?: 'RenewalOfferDecisionError' }
    | ({ __typename: 'RenewalOfferDecisionExpiredNonExistent' } & Pick<
        RenewalOfferDecisionExpiredNonExistent,
        'message'
      >)
    | ({ __typename: 'RenewalOfferDecisionMade' } & Pick<
        RenewalOfferDecisionMade,
        'message'
      >);
};

export type ResetAllRenewalOffersMutationVariables = Exact<{
  pricingType?: Maybe<PricingType>;
  rewards?: Maybe<Scalars['Boolean']>;
}>;

export type ResetAllRenewalOffersMutation = { __typename?: 'Mutation' } & {
  resetAllRenewalOffers: Array<
    { __typename?: 'RenewalOffer' } & Pick<RenewalOffer, 'id'>
  >;
};

export type SubmitNeedSomethingElseMutationVariables = Exact<{
  needs: Array<Scalars['String']> | Scalars['String'];
  additionalInfo: Scalars['String'];
  preferredTermId?: Maybe<Scalars['ID']>;
}>;

export type SubmitNeedSomethingElseMutation = { __typename?: 'Mutation' } & {
  submitNeedSomethingElse: { __typename?: 'NeedSomethingElse' } & Pick<
    NeedSomethingElse,
    'needs' | 'additionalInfo'
  >;
};

export type UpdateMoveOutInfoMutationVariables = Exact<{
  forwardingAddress?: Maybe<ForwardingAddressInput>;
  reasonForMoveOut: FormSingleSelectInput;
  noticeToVacateFlow: Scalars['Boolean'];
  moveOutDate?: Maybe<Scalars['Datetime']>;
}>;

export type UpdateMoveOutInfoMutation = { __typename?: 'Mutation' } & {
  updateMoveOutInfo: { __typename?: 'MoveOutInfo' } & MoveOutInfoFragment;
};

export type CreateUserTaskResponseMutationVariables = Exact<{
  userTaskId: Scalars['ID'];
  userTaskTemplate: TaskTemplate;
  response?: Maybe<Scalars['JSON']>;
}>;

export type CreateUserTaskResponseMutation = { __typename?: 'Mutation' } & {
  createUserTaskResponse: { __typename?: 'UserTaskResponse' } & Pick<
    UserTaskResponse,
    'userTaskId' | 'userTaskTemplate' | 'response'
  >;
};

export type SubmitRentersInsuranceInfoMutationVariables = Exact<{
  provider: Scalars['String'];
  liabilityCoverage: Scalars['Int'];
  policyStartDate: Scalars['Date'];
  policyEndDate: Scalars['Date'];
  document: Scalars['Upload'];
}>;

export type SubmitRentersInsuranceInfoMutation = { __typename?: 'Mutation' } & {
  submitRentersInsuranceInfo:
    | { __typename?: 'Error' }
    | ({ __typename?: 'RentersInsuranceInfo' } & Pick<
        RentersInsuranceInfo,
        | 'id'
        | 'provider'
        | 'liabilityCoverage'
        | 'policyStartDate'
        | 'policyEndDate'
        | 'documentUrl'
      >);
};

export type ToggleFavoriteMutationVariables = Exact<{
  housingId: Scalars['ID'];
  housingType: HousingType;
}>;

export type ToggleFavoriteMutation = { __typename?: 'Mutation' } & {
  toggleFavorite: { __typename?: 'Favorite' } & Pick<Favorite, 'active'> & {
      housing:
        | ({ __typename?: 'Community' } & CommunitySummaryFragment)
        | { __typename?: 'FloorPlan' }
        | { __typename?: 'Unit' };
    };
};

export type GetCommunityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCommunityQuery = { __typename?: 'Query' } & {
  community?: Maybe<{ __typename?: 'Community' } & CommunityFragment>;
};

export type GetMatchingCommunitiesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  filters?: Maybe<Array<FilterInput> | FilterInput>;
  optionalFilterStrings?: Maybe<
    Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
  >;
  geolocationType: GeolocationType;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}>;

export type GetMatchingCommunitiesQuery = { __typename?: 'Query' } & {
  matchingCommunities?: Maybe<
    {
      __typename?: 'MatchingCommunitiesOutput';
    } & MatchingCommunitiesOutputFragment
  >;
};

export type GetMatchingCommunitiesLegacyQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  filterString: Scalars['String'];
  optionalFilterStrings?: Maybe<
    Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
  >;
  geolocationType: GeolocationType;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}>;

export type GetMatchingCommunitiesLegacyQuery = { __typename?: 'Query' } & {
  matchingCommunities?: Maybe<
    {
      __typename?: 'MatchingCommunitiesOutput';
    } & MatchingCommunitiesOutputFragment
  >;
};

export type DownloadableFileUrlQueryVariables = Exact<{
  objectId: Scalars['ID'];
  objectType: FileObjectType;
}>;

export type DownloadableFileUrlQuery = { __typename?: 'Query' } & {
  downloadableFileUrl?: Maybe<
    | ({ __typename?: 'DownloadableFileUrl' } & Pick<
        DownloadableFileUrl,
        'url'
      >)
    | { __typename?: 'Error' }
  >;
};

export type ESignatureEmbedUrlQueryVariables = Exact<{
  eSignedNoticeToVacateID: Scalars['ID'];
}>;

export type ESignatureEmbedUrlQuery = { __typename?: 'Query' } & {
  eSignatureEmbedUrl?: Maybe<
    | ({ __typename: 'ESignatureEmbedPayload' } & Pick<
        ESignatureEmbedPayload,
        'url'
      >)
    | { __typename?: 'ESignatureEmbedResponseError' }
  >;
};

export type LeaseSigningDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type LeaseSigningDetailsQuery = { __typename?: 'Query' } & {
  leaseSigningDetails?: Maybe<
    { __typename?: 'LeaseSigningDetails' } & LeaseSigningDetailsFragment
  >;
};

export type GetFloorPlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetFloorPlanQuery = { __typename?: 'Query' } & {
  floorPlan?: Maybe<{ __typename?: 'FloorPlan' } & FloorPlanFragment>;
};

export type GetHousingInterestQueryVariables = Exact<{ [key: string]: never }>;

export type GetHousingInterestQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        interestedHousing: Array<
          | ({ __typename?: 'Community' } & CommunitySummaryFragment)
          | { __typename?: 'FloorPlan' }
          | { __typename?: 'Unit' }
        >;
      }
  >;
};

export type GetOnboardingUserQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetOnboardingUserQuery = { __typename?: 'Query' } & {
  onboardingUser?: Maybe<
    | ({ __typename: 'OnboardingUser' } & OnboardingUserFragment)
    | ({ __typename: 'UnknownOnboardingUser' } & Pick<
        UnknownOnboardingUser,
        'message'
      >)
  >;
};

export type GetRenewalDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetRenewalDataQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & RenewalDataFragment
  >;
};

export type GetUpgradePlacesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUpgradePlacesQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & UpgradeDataFragment
  >;
};

export type GetCurrentUserTasksQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserTasksQuery = { __typename?: 'Query' } & {
  currentUserTasks: Array<{ __typename?: 'UserTask' } & UserTaskFragment>;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<{ __typename?: 'User' } & UserFragment>;
};

export type GetUserCommunityQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserCommunityQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        currentLease: { __typename?: 'Lease' } & Pick<Lease, 'id'> & {
            community: { __typename?: 'Community' } & CurrentCommunityFragment;
          };
      }
  >;
};

export type GetUserEnabledFeaturesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserEnabledFeaturesQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id' | 'enabledFeatures'>
  >;
};

export type GetUserExperimentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserExperimentsQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        experiments: Array<
          { __typename?: 'UserExperiment' } & UserExperimentFragment
        >;
      }
  >;
};

export type GetUserFavoritesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserFavoritesQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & CurrentFavoritesFragment
  >;
};

export type GetUserFutureLeaseQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserFutureLeaseQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        futureLease?: Maybe<
          { __typename?: 'FutureLease' } & FutureLeaseFragment
        >;
      }
  >;
};

export type GetUserIncentivesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserIncentivesQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        incentives: { __typename?: 'Incentive' } & IncentiveFragment;
      }
  >;
};

export type GetUserLeaseQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserLeaseQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        currentLease: { __typename?: 'Lease' } & LeaseFragment;
      }
  >;
};

export type GetUserPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserPreferencesQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        preferences: { __typename?: 'UserPreference' } & UserPreferenceFragment;
      }
  >;
};

export type GetUserRenewalOfferQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserRenewalOfferQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        currentLease: { __typename?: 'Lease' } & Pick<Lease, 'id'> & {
            renewalOffer?: Maybe<
              { __typename?: 'RenewalOffer' } & Pick<RenewalOffer, 'id'> &
                RenewalOfferFragment
            >;
          };
      }
  >;
};

export type GetUserSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserSummaryQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<{ __typename?: 'User' } & UserSummaryFragment>;
};
