import useLoggedInStatus from '$/graphql/hooks/auth/useLoggedInStatus';
import useCurrentUserTasks from '$/graphql/hooks/user/useCurrentUserTasks';
import initVar from '$/graphql/variables/init';
import { from } from '$/styles/responsive';
import { useReactiveVar } from '@apollo/client';
import { useMediaQuery } from '@mui/material';

import { useLoginRedirect } from './logic';

export default function useConnect() {
  const isDesktop = useMediaQuery(from.tabletLandscape.query, { noSsr: true });
  const { data: userTasks } = useCurrentUserTasks();
  const status = useLoggedInStatus();
  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time client side
  const isAuthenticated = init && status === 'logged-in';
  const hasTasks = userTasks && userTasks?.length > 0;

  useLoginRedirect(status);

  return {
    hasTasks,
    isDesktop,
    isAuthenticated,
  };
}
