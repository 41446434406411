/* eslint-disable @typescript-eslint/no-floating-promises */
import useLoggedInStatus from '$/graphql/hooks/auth/useLoggedInStatus';
import GET_CURRENT_USER_TASKS from '$/graphql/queries/user/getCurrentUserTasks';
import initVar from '$/graphql/variables/init';
import normalizeUserTasks from '$/model/UserTasks';
import { withoutNulls } from '$/utils/withoutNulls';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';

export default function useCurrentUserTasks() {
  const status = useLoggedInStatus();
  const [getUserTasks, { data: raw, loading, refetch }] = useLazyQuery(
    GET_CURRENT_USER_TASKS,
  );

  const init = useReactiveVar(initVar);

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.currentUserTasks);
    return data && normalizeUserTasks(data);
  }, [raw]);

  useEffect(() => {
    if (init && status === 'logged-in') {
      getUserTasks();
    }
  }, [getUserTasks, status, init]);

  return {
    data: normalized,
    loading,
    refetch,
  };
}
