import analytics from '$/analytics';
import Logo from '$/assets/images/renew-logo.svg';
import InboxIcon from '@mui/icons-material/InboxOutlined';
import { Badge } from '@mui/material';
import { memo } from 'react';

import * as Styled from './styles';
import { Props } from './types';

function TopBar({
  className,
  handleBack,
  text,
  withoutBorder,
  hideUser = false,
  openMenu,
  residentTasksEnabled,
  hasTasks,
}: Props): JSX.Element {
  return (
    <Styled.Bar $withoutBorder={withoutBorder} className={className}>
      <Styled.Left>
        {handleBack && (
          <>
            <Styled.Button onClick={handleBack}>
              <Styled.ArrowLeftIcon />
            </Styled.Button>
            <Styled.Divider />
          </>
        )}
        <Styled.Button onClick={() => openMenu()}>
          <Styled.MenuIcon />
        </Styled.Button>
      </Styled.Left>

      <Styled.Center>
        {text ? (
          <Styled.Text>{text}</Styled.Text>
        ) : (
          <Styled.LogoLink href="/" passHref>
            <Logo aria-label="Renew" />
          </Styled.LogoLink>
        )}
      </Styled.Center>

      {!hideUser && (
        <Styled.Right>
          {residentTasksEnabled ? (
            <Styled.Link
              href="/tasks"
              aria-label="Tasks"
              onClick={() =>
                analytics?.track('app.nav-button.clicked', {
                  label: 'Tasks',
                })
              }
              passHref
            >
              <Badge
                color="warning"
                overlap="circular"
                variant="dot"
                badgeContent={hasTasks ? 1 : 0}
              >
                <InboxIcon />
              </Badge>
            </Styled.Link>
          ) : (
            <Styled.Link href="/profile" passHref>
              <Styled.UserIcon />
            </Styled.Link>
          )}
        </Styled.Right>
      )}
    </Styled.Bar>
  );
}

export default memo(TopBar);
