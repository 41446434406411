import analytics from '$/analytics';
import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import useConnect from './connect';

export default function AnalyticsProvider(): null {
  const router = useRouter();
  const [datadogIsInitialized, setDatadogIsInitialized] = useState(false);
  const { isAuthenticated, onboardingUser, authCheckData } = useConnect();

  useEffect(() => {
    /**
     * This hook calls Segment's page() on route changes.
     */
    router.events.on('routeChangeComplete', (url) => {
      analytics?.page(url);
    });
  }, [router.events]);

  const identifyAnalytics = useCallback(
    async (isOnboardingUser: boolean) => {
      const segmentUser = await analytics?.user();
      const userTraits = segmentUser?.traits();

      if (isOnboardingUser && segmentUser.id() === null) {
        analytics?.identify?.(onboardingUser?.id, {
          renew_user_id: onboardingUser?.id,
        });
        analytics?.group?.(onboardingUser?.currentLease.community.name, {
          community_id: onboardingUser?.currentLease.community.id,
          community_name: onboardingUser?.currentLease.community.name,
          property_manager_name:
            onboardingUser?.currentLease.community.propertyManager,
        });
      } else if (segmentUser.id === null || !userTraits?.email) {
        analytics?.identify(authCheckData?.user?.id, {
          email: authCheckData?.user?.email,
          renew_user_id: authCheckData?.user?.id,
        });
        analytics?.group?.(authCheckData?.community?.name, {
          community_id: authCheckData?.community?.name,
          community_name: authCheckData?.community?.name,
          property_manager_name: authCheckData?.community?.propertyManager,
        });
      }
    },
    [onboardingUser, authCheckData],
  );

  useEffect(() => {
    /**
     * This hook runs Segment's identify() and group(), and DD RUM's setUser() when authenticated.
     * On logout or component unmount, run DD RUM's removeUser()
     */
    if (isAuthenticated && authCheckData?.community && authCheckData.user) {
      // Call Segment Identify and Group with traits Product team wants for tracking if it's not already set
      void identifyAnalytics(false);
      // Call DD RUM setUser
      if (window.DD_RUM) {
        datadogRum.setUser?.({
          id: authCheckData.user.id,
          email: authCheckData.user.email,
        });
      }
    } else if (onboardingUser) {
      void identifyAnalytics(true);
      if (window.DD_RUM) {
        datadogRum.setUser?.({
          id: onboardingUser.id,
        });
      }
    } else {
      // reset any previously set User or Group traits when not authenticated
      datadogRum.removeUser?.();
    }
  }, [isAuthenticated, authCheckData, onboardingUser, identifyAnalytics]);

  // initialize datadog RUM (run server-side)
  if (
    !datadogIsInitialized &&
    ['staging', 'production'].includes(
      process.env['NEXT_PUBLIC_ENV']?.toLowerCase() as string,
    )
  ) {
    datadogRum.init({
      applicationId: process.env[
        'NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID'
      ] as string,
      clientToken: process.env[
        'NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN'
      ] as string,
      site: 'datadoghq.com',
      service: 'renew-web',
      version: process.env['VERCEL_GIT_COMMIT_SHA'],
      env: process.env['NEXT_PUBLIC_ENV'],
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        'https://staging.api.heyrenew.com',
        'https://renew-be-staging.herokuapp.com',
        'https://renew-be-production.herokuapp.com',
        'https://api.heyrenew.com',
      ],
      traceSampleRate: 100,
    });
    datadogRum.startSessionReplayRecording();
    setDatadogIsInitialized(true);
  }
  return null;
}
