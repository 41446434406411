import USER_TASK_FRAGMENT from '$/graphql/fragments/UserTask';
import type {
  GetCurrentUserTasksQuery,
  GetCurrentUserTasksQueryVariables,
} from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const GET_CURRENT_USER_TASKS: TypedDocumentNode<
  GetCurrentUserTasksQuery,
  GetCurrentUserTasksQueryVariables
> = gql`
  query GetCurrentUserTasks {
    currentUserTasks {
      ...UserTask
    }
  }

  ${USER_TASK_FRAGMENT}
`;

export default GET_CURRENT_USER_TASKS;
